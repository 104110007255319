import React, { useState } from "react";
import { OverlayContextProvider } from "../../../modules/vehicle-search/components/OverlayContext";
import { FilterStateProvider } from "../../../modules/vehicle-search/components/filters/FilterStateContext";
import VehicleSearch from "../../../modules/vehicle-search/components/VehicleSearch";
import useVehicleSearchData from "../../../modules/algolia/hooks/useVehicleSearchData";
import useVehicleSearchTitle from "../../../modules/vehicle-search/hooks/useVehicleSearchTitle";
import { useFullWhitelabelConfig } from "../../core/components/WhitelabelConfig";
import { FilterRenderConfigurationProvider } from "../../../modules/vehicle-search/components/FilterRenderer";
import buildTitle from "../../../modules/common/helpers/buildTitle";
import WhitelabelTitleSync from "../../core/components/WhitelabelTitleSync";
import DefaultNoResults from "../../../modules/vehicle-search/components/DefaultNoResults";
import { SimpleBrandsFilterContext } from "../../../modules/vehicle-search/components/filters/BrandModelFilter";
import { Context } from "../../../modules/vehicle-search/helpers/contexts";

type Props = {
  dealerIds: number[];
  brandIds?: number[];
};

const DEALER_VEHICLE_SEARCH_PAGE_LIMIT = 12;

const DealerVehicleSearch: React.FC<Props> = ({ dealerIds, brandIds }) => {
  const { sidebarFilters, excludedFilters, searchPlaceholder } = useFullWhitelabelConfig();

  const vehicleSearchData = useVehicleSearchData({
    dealerRestrictions: dealerIds,
    brandRestrictions: brandIds,
    pageSize: DEALER_VEHICLE_SEARCH_PAGE_LIMIT,
    contexts: brandIds && brandIds.length > 0 ? undefined : [Context.AllVehicles],
  });

  const { appliedFilters, filterData, postedQuery, algoliaOptions, resetFilters } = vehicleSearchData;

  const [showSearchOverlay, setShowSearchOverlay] = useState(false);

  const { title } = useVehicleSearchTitle(appliedFilters, filterData);

  return (
    <FilterRenderConfigurationProvider excludedFilters={excludedFilters ?? []} sidebarFilters={sidebarFilters}>
      <SimpleBrandsFilterContext.Provider value={true}>
        <OverlayContextProvider isOpen={showSearchOverlay} setIsOpen={setShowSearchOverlay}>
          <FilterStateProvider>
            <WhitelabelTitleSync title={buildTitle(title, false)} />
            <VehicleSearch
              disableMinHeightFix
              searchPlaceholder={searchPlaceholder}
              title={title}
              vehicleSearchData={vehicleSearchData}
              noResults={
                <DefaultNoResults
                  algoliaOptions={algoliaOptions}
                  postedQuery={postedQuery}
                  resetFilters={resetFilters}
                />
              }
            />
          </FilterStateProvider>
        </OverlayContextProvider>
      </SimpleBrandsFilterContext.Provider>
    </FilterRenderConfigurationProvider>
  );
};

export default DealerVehicleSearch;
