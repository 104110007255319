import i18next from "i18next";
import React, { forwardRef } from "react";
import VehiclesCarousel from "../../../modules/vehicle/components/VehiclesCarousel";
import AlgoliaVehicleSearchCarCard from "../../../modules/vehicle/components/AlgoliaVehicleSearchCarCard";
import { getHandle } from "../../../modules/hygraph/helpers/assetHelpers";
import { IMAGE_FIT_TYPES, IMAGE_PROVIDER_MAGAZINE_BASE_URL } from "../../../modules/visual-components/components/Image";
import useVehicleSearchData from "../../../modules/algolia/hooks/useVehicleSearchData";
import useContentButton from "../../../modules/cms/hooks/useContentButton";
import { ButtonAnchor } from "../../../modules/visual-components/components/Button";
import { setUtmParams, UtmParams } from "../../core/services/setUtmParams";
import ImageTwoSizes, { IMAGE_TRANSFORM_TYPES } from "../../../modules/visual-components/components/ImageTwoSizes";
import { TrackingReferrer } from "../../../modules/analytics/constants/TrackingReferrer";
import { useSlideContext } from "./SlideContext";

const NEWLINE_CHAR = "&#10;";

type Props = {
  utmParams: UtmParams;
};

const BannerSlide = forwardRef<HTMLDivElement, Props>(({ utmParams }, ref) => {
  const { slide } = useSlideContext();
  const { vehicleData: vehiclesToShow } = useVehicleSearchData({
    pageSize: 10,
  });

  const link = useContentButton({ link: slide.ctaLink.to, absolute: true });

  if (!vehiclesToShow?.hits) {
    return null;
  }

  const alt = slide.imageDesktop?.altText ?? slide.imageMobile?.altText ?? "";
  const desktopImageHandle = getHandle(slide.imageDesktop?.media);
  const mobileImageHandle = getHandle(slide.imageMobile?.media);

  const titleParts = slide.title.split(NEWLINE_CHAR);

  return (
    <div ref={ref} className="advertising__item">
      <div className="advertising__left">
        <div className="advertising__img-wrap">
          <ImageTwoSizes
            alt={alt}
            className="advertising__img"
            desktopSrc={IMAGE_PROVIDER_MAGAZINE_BASE_URL + desktopImageHandle}
            fit={IMAGE_FIT_TYPES.crop}
            imageSizes={IMAGE_TRANSFORM_TYPES.whitelabelBanner}
            loading="eager"
            mobileSrc={IMAGE_PROVIDER_MAGAZINE_BASE_URL + mobileImageHandle}
          />
        </div>
        <p className="advertising__title">
          {titleParts.map((titlePart, k) =>
            k === titleParts.length - 1 ? (
              <React.Fragment key={k}>{titlePart}</React.Fragment>
            ) : (
              <React.Fragment key={k}>
                {titlePart}
                <br />
              </React.Fragment>
            ),
          )}
        </p>
        <ButtonAnchor href={setUtmParams(link.to, utmParams)} target="_blank" transparent={true}>
          {slide.ctaLabel}
        </ButtonAnchor>
      </div>
      <div className="advertising__right">
        <section className="section-carousel">
          <VehiclesCarousel title={i18next.t("SUITABLE VEHICLES")} totalItems={vehiclesToShow.hits.length}>
            {vehiclesToShow.hits.map(vehicle => (
              <AlgoliaVehicleSearchCarCard
                key={vehicle.vehicleId}
                hideExtraInfo
                singleImage
                trackingReferrer={TrackingReferrer.None}
                vehicle={vehicle}
              />
            ))}
          </VehiclesCarousel>
        </section>
      </div>
    </div>
  );
});

export default BannerSlide;
